import axios from 'axios';

const API_BASE_URL = 'https://paperai.in/api'; // Production API URL
// const API_BASE_URL = 'https://localhost:8000'; // Production API URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to include auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const authService = {
  login: async (identifier, password) => {
    try {
      const response = await api.post('/api/auth/login', { identifier, password });
      const { access_token, refresh_token, role, name, email } = response.data;
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('userRole', role);
      localStorage.setItem('userName', name);
      localStorage.setItem('userEmail', email);
      // if(access_token){
      //   api.headers.Authorization = `Bearer ${token}`;
      // }
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Login failed';
    }
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
  }
};

export const teacherService = {
  getProfile: async () => {
    try {
      const response = await api.get('/api/profile');
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getProfile: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch profile';
    }
  },
  getClassesHandled: async () => {
    try {
      const response = await api.get('/api/staff/classes');
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getClassesHandled: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch classes';
    }
  },

  getExam:  async (class_id) => {
    try {
      //https://www.paperai.in/api/api/exams?class_id=1
      const response = await api.get(`/api/exams?class_id=${class_id}`);
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getClassesHandled: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch classes';
    }
  },

  getExamDetails: async (class_id) => {
    try {
      //https://www.paperai.in/api/api/exams?class_id=1
      const response = await api.get(`/api/exams?class_id=${class_id}`);
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getClassesHandled: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch classes';
    }
  },

  getClassData: async (exam, student) => {
    try {
      // https://www.paperai.in/api/api/exams/Quarterly/students/6/subjects
      const response = await api.get(`/api/exams/${exam}/students/${student}/subjects`);
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getClassData: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch classes';
    }
  },

  getStudentsDetail: async (classId, staffId) => {  
    // console.log('🚀🚀🚀🚀🚀 ============================= ~ getStudentsDetail: ~ classId:', classId);
    // https://www.paperai.in/api/api/staff/classes/1/3/students 
    try {
      const response = await api.get(`/api/staff/classes/${classId}/${staffId}/students`);
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getClassData: ~ response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch classes';
    }

  },

  getStudentMarks: async (exam, studentId) => {
    // console.log('🚀🚀🚀🚀🚀 ============================= ~ getStudentMarks: ~ exam:', exam);
    // console.log('🚀🚀🚀🚀🚀 ============================= ~ getStudentMarks: ~ studentId:', studentId);
    // `https://www.paperai.in/api/api/exams/Quarterly/students/${student.id}/subjects`

    try {
      const response = await api.get(`/api/exams/${exam}/students/${studentId}/subjects`);
      // console.log('🚀🚀🚀🚀🚀 ============================= ~ getStudentMarks: ~ response:', JSON.stringify(response.data));
      
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch student marks';
    }
  },

};

export const emailService = {
  sendContactEmail: async (formData) => {
    try {
      const response = await api.post('api/mail/send', formData);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to send email';
    }
  }
};
