import React from 'react';
import { Box, Typography, Button, Container, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginModal from './LoginModal';

import introImage from '../img/new/image1.1.png';
import curveImage from '../img/curve1.png';
import starImage from '../img/star.png';


const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #6366F1, #EC4899, #EF4444)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent'
}));

const Intro = () => {
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const handleGetStarted = () => {
    setLoginModalOpen(true);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        // background: 'linear-gradient(135deg, #EEF2FF 0%, #F5F3FF 100%)',
        background: 'linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgb(248, 204, 255) 50%, rgb(189, 209, 255) 100%)',

        position: 'relative',
        overflow: 'hidden',
        py: 8,
      }}
    >
      {/* Floating Background Elements */}
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: 250,
          height: 150,
          bgcolor: 'rgba(79, 79, 213, 0.1)',
          borderRadius: 2,
          animation: `${float} 6s ease-in-out infinite`,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          borderColor: 'rgb(0, 0, 0)',
        //   boxShadow: '4px 4px 0px black',

          top: '15%',
          right: '5%',
          width: 250,
          height: 150,
          bgcolor: 'rgba(237, 78, 64, 0.1)',
          borderRadius: 2,
          animation: `${float} 4s ease-in-out infinite`,
          animationDelay: '1s',
        }}
      />

      <Container maxWidth="lg">
        {/* Text Content */}
        <Box
          sx={{
            textAlign: 'center',
            mb: 8,
            mt: 8,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '4rem' },
              fontWeight: 700,
              mb: 3,
            }}
          >
            Transform Education
            <br />
            With{' '}
            <GradientTypography
              component="span"
              sx={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Paper AI
            </GradientTypography>
          </Typography>

          <Typography
            variant="h5"
            sx={{
              color: 'text.secondary',
              mb: 4,
              lineHeight: 1.6,
              fontSize: '1.5rem',
            }}
          >
            Revolutionizing the way teachers teach and students learn
            <br />
            with{' '}
            <Box
              component="span"
              sx={{ color: 'primary.main' }}
            >
              advanced AI-powered
            </Box>{' '}
            tools
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={handleGetStarted}
            sx={{
                background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                color: 'white',
                borderRadius: '50px',
                padding: '16px 36px',
                textTransform: 'none',
                fontSize: '20px',
                fontWeight: 600,
                width: 'fit-content',
                boxShadow: 'none',
                transition: 'all 0.3s', 
                '&:hover': {
                  background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                  transform: 'translate(-4px, -4px)',
                  boxShadow: '4px 4px 0px black',
                },
              }}
          >
            Start Your Journey
          </Button>
        </Box>

        {/* Image Container */}
        <Box
          sx={{
            position: 'relative',
            maxWidth: 'lg',
            mx: 'auto',
          }}
        >
            <Box
                // sx={{
                //     bgcolor: '#8B5CF6',
                //     borderRadius: '24px',
                //     p: '3px',
                //     width: '100%',
                //     position: 'relative',
                //     zIndex: 2,
                //     overflow: 'hidden'
                // }}
            >
            <Box
                sx={{
                position: 'relative',
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: theme => theme.shadows[20],
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    background: 'linear-gradient(135deg, rgba(167, 139, 250, 0.1), rgba(96, 165, 250, 0.1))',
                    zIndex: 1,
                },
                }}
            >
                <Box
                component="img"
                src={introImage}
                alt="Student using AI learning platform"
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                    objectFit: 'cover',
                }}
                />
            </Box>
          </Box>

          {/* Decorative Corner Elements */}
          <Box
            sx={{
              position: 'absolute',
              top: -16,
              left: -16,
              width: 80,
              height: 80,
              bgcolor: 'primary.main',
              opacity: 0.2,
              borderRadius: '16px',
              transform: 'rotate(45deg)',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -16,
              right: -16,
              width: 80,
              height: 80,
              bgcolor: 'primary.main',
              opacity: 0.2,
              borderRadius: '16px',
              transform: 'rotate(-45deg)',
            }}
          />
          <Box
            component="img"
            src={curveImage}
            sx={{
              position: 'absolute',
              zIndex: 2,
              top: -100,
              right: -150,
              width: 250,
              height: 230,
              bgcolor: 'primary.main',
              opacity: 1,
              borderRadius: '16px',
              background: 'transparent',
              transform: 'rotate(-10deg)',
            }}
          />

            <Box
            component="img"
            src={starImage}
            sx={{
                position: 'absolute',
                // zIndex: 2,
                bottom: -80,
                left: -70,
                width: 150,
                height: 150,
                bgcolor: 'primary.main',
                opacity: 1,
                borderRadius: '16px',
                background: 'transparent',
                transform: 'rotate(-10deg)',
              }}
          />

        </Box>
      </Container>
      <LoginModal 
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </Box>
  );
};

export default Intro;