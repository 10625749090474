import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Alert,
  useTheme,
  Card,
  CardContent,
  Stack,
  Divider,
  alpha,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarksTableModern from './MarksTableModern';
import StudentGraphsModern from './StudentGraphsModern';
import LogoutIcon from '@mui/icons-material/Logout';
import ClassIcon from '@mui/icons-material/Class';
import SubjectIcon from '@mui/icons-material/Subject';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import Logo from '../components/Logo';

import { teacherService, authService } from '../services/api';

const DashboardModern = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState(null);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedExam, setSelectedExam] = useState('');
  const [examData, setExamData] = useState({});
  const [classData, setClassData] = useState({});
  const [selectedSubject, setSelectedSubject] = useState('');
  const [studentsData, setStudentsData] = useState([]);
  const [subjectData, setSubjectData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          navigate('/');
          return;
        }

        setLoading(true);
        const [profileData, classesData] = await Promise.all([
          teacherService.getProfile(),
          teacherService.getClassesHandled(),
        ]);

        // Process class data
        const processedData = classesData.classes.reduce((acc, classItem) => {
          const combinedClassName = `${classItem.class_name}-${classItem.section}`;
          acc[combinedClassName] = { ...classItem };
          return acc;
        }, {});

        setClassData(processedData);

        // Set teacher data
        setTeacher({
          ...profileData,
          classesHandled: classesData.classes.map(c => `${c.class_name}-${c.section}`),
          subjects: classesData?.department || {},
          classTeacherOf: classesData?.role === 'class_teacher' 
            ? 'class_teacher' 
            : classesData?.class_name != null && classesData?.section != null 
              ? `${String(classesData.class_name)}-${String(classesData.section)}` 
              : '',
          name: profileData?.name || '',
          employeeId: profileData?.identifier || '',
        });
      } catch (error) {
        setError(`Failed to load teacher data. Please try again. - ${error}`);
        console.error('Error fetching teacher data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherData();
  }, [navigate]);

  useEffect(() => {
    if (teacher && teacher.classesHandled && teacher.classesHandled.length > 0) {
      const firstClass = teacher.classesHandled[0];
      handleClassSelect(firstClass);
    }
  }, [teacher]);

  useEffect(() => {
    if (selectedClass && Object.keys(examData).length > 0) {
      const firstExam = Object.keys(examData)[0];
      handleExamSelect(firstExam);
    }
  }, [selectedClass, examData]);

  const findClassIdByName = (className) => {
    const classDetails = classData[className];
    return classDetails ? classDetails.class_id : null;
  };

  const handleClassSelect = async (className) => {
    try {
      const classId = findClassIdByName(className);
      setSelectedClass(className);
      setSelectedExam(''); // Reset exam selection when class changes
      
      const examResponse = await teacherService.getExam(classId);
      const grouped = examResponse.exams.reduce((acc, exam) => {
        if (!acc[exam.exam_name]) {
          acc[exam.exam_name] = [];
        }
        acc[exam.exam_name].push(exam);
        return acc;
      }, {});
      setExamData(grouped);

      if (teacher?.classTeacherOf === className) {
        setSelectedSubject('all');
      } else {
        setSelectedSubject('');
      }
    } catch (error) {
      setError('Failed to fetch exam data');
      console.error('Error in handleClassSelect:', error);
    }
  };

  const handleExamSelect = async (examName) => {
    try {
      setLoading(true);
      setError('');
      setSelectedExam(examName);
      
      const classId = findClassIdByName(selectedClass);
      if (!classId) {
        throw new Error('Class ID not found');
      }

      const staffId = teacher.id;
      
      // Fetch students list
      const studentsResponse = await teacherService.getStudentsDetail(classId, staffId);
      const students = studentsResponse.students;
      setStudentsData(students);

      // Fetch marks for each student
      const marksPromises = students.map(student => 
        teacherService.getStudentMarks(examName, student.id)
          .then(response => ({
            studentName: student.name,
            subjects: response.subjects
          }))
          .catch(error => {
            console.error(`Error fetching marks for student ${student.name}:`, error);
            return {
              studentName: student.name,
              subjects: []
            };
          })
      );

      const marksResults = await Promise.all(marksPromises);
      
      // Convert array of results to object with student names as keys
      const marksData = marksResults.reduce((acc, result) => {
        acc[result.studentName] = result.subjects;
        return acc;
      }, {});

      setSubjectData(marksData);
    } catch (error) {
      console.error('Error in handleExamSelect:', error);
      setError('Failed to fetch exam data');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    authService.logout();
    navigate('/');
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Enhanced Header */}
      <Paper 
        elevation={3} 
        sx={{ 
          height: 80,
          background: 'linear-gradient(120deg, #ffffff 0%, #f5f5f5 100%)',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1100,
        }}
      >
        <Box 
          sx={{ 
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1400,
            mx: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Logo />
            {/* <Divider orientation="vertical" flexItem />
            <Stack direction="row" spacing={2}>
              <Chip
                icon={<SchoolIcon />}
                label={`Class: ${selectedClass || 'Not Selected'}`}
                color="primary"
                variant="outlined"
              />
              <Chip
                icon={<SubjectIcon />}
                label={`Exam: ${selectedExam || 'Not Selected'}`}
                color="primary"
                variant="outlined"
              />
            </Stack> */}
          </Box>
          
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              // bgcolor: alpha(theme.palette.primary.main, 0.1),
              p: 1,
              borderRadius: 1
            }}>
              <Avatar 
                sx={{ 
                  bgcolor: theme.palette.primary.main,
                  width: 32,
                  height: 32
                }}
              >
                {teacher?.name?.charAt(0) || 'T'}
              </Avatar>
              <Box>
                <Typography variant="subtitle2" color="primary.main">
                  {teacher?.name || 'Teacher'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  ID: {teacher?.employeeId || 'N/A'}
                </Typography>
              </Box>
            </Box>
            
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<LogoutIcon />}
              onClick={() => {
                localStorage.removeItem('accessToken');
                navigate('/');
              }}
              sx={{ 
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4
                }
              }}
            >
              Logout
            </Button> */}
          </Stack>
        </Box>
      </Paper>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} sx={{ p: 3 }}>
        {/* Teacher Profile Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Stack spacing={2} alignItems="center">
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: theme.palette.primary.main
                  }}
                >
                  <PersonIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">
                  {teacher?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Employee ID: {teacher?.employeeId}
                </Typography>
                <Divider flexItem />
                <Stack direction="row" spacing={1}>
                  <Chip
                    icon={<ClassIcon />}
                    label={`Classes: ${teacher?.classesHandled?.length || 0}`}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<SubjectIcon />}
                    label={`Subjects: ${teacher?.department || {}}`}
                    color="secondary"
                    variant="outlined"
                  />
                </Stack>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                  fullWidth
                >
                  Logout
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Class and Exam Selection */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                {/* Class Selection */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Select Class
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    {teacher?.classesHandled?.map((className) => (
                      <Button
                        key={className}
                        variant={selectedClass === className ? "contained" : "outlined"}
                        onClick={() => handleClassSelect(className)}
                        sx={{ mb: 1 }}
                      >
                        {className}
                      </Button>
                    ))}
                  </Stack>
                </Box>

                {/* Exam Selection */}
                {selectedClass && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Select Exam
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                      {Object.keys(examData).map((examName) => (
                        <Button
                          key={examName}
                          variant={selectedExam === examName ? "contained" : "outlined"}
                          onClick={() => handleExamSelect(examName)}
                          sx={{ mb: 1 }}
                        >
                          {examName}
                        </Button>
                      ))}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>

          {/* Quick Stats Card */}
          {selectedExam && studentsData.length > 0 && (
            <Card sx={{ mt: 3 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                      <Typography variant="h6">Total Students</Typography>
                      <Typography variant="h4">{studentsData.length}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                      <Typography variant="h6">Average Score</Typography>
                      <Typography variant="h4">
                        {Math.round(
                          Object.values(subjectData).reduce((acc, student) => {
                            const marks = student.reduce((sum, subject) => 
                              sum + (subject.marks_obtained || 0), 0);
                            return acc + marks;
                          }, 0) / (studentsData.length || 1)
                        )}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                      <Typography variant="h6">Pass Rate</Typography>
                      <Typography variant="h4">
                        {Math.round(
                          (Object.values(subjectData).filter(student => 
                            student.every(subject => 
                              (subject.marks_obtained || 0) >= (subject.total_marks || 0) * 0.4
                            )
                          ).length / (studentsData.length || 1)) * 100
                        )}%
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>

        {/* Marks Table */}
        {selectedExam && (
          <Grid item xs={12}>
            <MarksTableModern
              subjectData={subjectData}
              students={studentsData}
              selectedExam={selectedExam}
            />
          </Grid>
        )}

        {/* Student Graphs */}
        {selectedExam && Object.keys(subjectData).length > 0 && (
          <Grid item xs={12}>
            <StudentGraphsModern
              subjectData={subjectData}
              students={studentsData}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardModern;
