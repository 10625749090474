import React from 'react';

const PolicySection = ({ title, children, icon }) => (
  <div className="mb-8 bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow">
    <h2 className="text-2xl font-semibold mb-4 flex items-center gap-2 text-indigo-700">
      {icon}
      {title}
    </h2>
    <div className="space-y-4 text-gray-600">{children}</div>
  </div>
);

const BulletList = ({ items }) => (
  <ul className="space-y-3">
    {items.map((item, index) => (
      <li key={index} className="flex items-start gap-2">
        <span className="h-2 w-2 mt-2 rounded-full bg-indigo-400 flex-shrink-0" />
        <span className="text-gray-600">{item}</span>
      </li>
    ))}
  </ul>
);

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-lg mb-8 p-8 text-center transform hover:scale-[1.02] transition-transform">
          <div className="bg-gradient-to-r from-indigo-600 to-purple-600 text-transparent bg-clip-text">
            <h1 className="text-4xl font-bold mb-2">Privacy Policy</h1>
          </div>
          <div className="flex items-center justify-center gap-2 text-gray-500">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Last Updated: 02/13/2025</span>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Introduction */}
          <PolicySection 
            title="Introduction"
            icon={
              <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          >
            <div className="p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg">
              <p>
                Welcome to PaperAI! Your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, 
                use, and safeguard your personal information when you use our app and services.
              </p>
            </div>
          </PolicySection>

          {/* Information We Collect */}
          <PolicySection
            title="Information We Collect"
            icon={
              <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            }
          >
            <div className="space-y-4">
              <p>We collect the following types of information to provide and improve our services:</p>
              <BulletList items={[
                "Personal Information: Name, email address, student ID, and other details provided by educational institutions.",
                "Usage Data: Information about how you interact with our app, including access logs, feature usage, and session duration.",
                "Device Information: Details about the device you use to access PaperAI."
              ]} />
            </div>
          </PolicySection>

          {/* How We Use Your Information */}
          <PolicySection
            title="How We Use Your Information"
            icon={
              <svg className="w-6 h-6 text-pink-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0-4H3m15 0h3m-3-7a7 7 0 00-7-7m0 0a7 7 0 00-7 7m7-7v4m0-4h14m-14 0H3" />
              </svg>
            }
          >
            <div className="space-y-4">
              <p>We use the collected information to:</p>
              <BulletList items={[
                "Deliver and enhance PaperAI’s features and services.",
                "Personalize the learning experience for students.",
                "Track academic progress and facilitate communication between students, parents, and educators.",
                "Ensure the security of our platform and prevent unauthorized access.",
                "Comply with legal and regulatory obligations."
              ]} />
            </div>
          </PolicySection>

          {/* How We Share Your Information */}
          <PolicySection
            title="How We Share Your Information"
            icon={
              <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
              </svg>
            }
          >
            <div className="space-y-4">
              <p>We do not sell or share your personal data with third-party advertisers. However, we may share data:</p>
              <BulletList items={[
                "With Schools and Educational Institutions: To manage student assessments, performance tracking, and academic reporting.",
                "With Service Providers: Who assist us in app functionality, hosting, and security, under strict confidentiality agreements.",
                "For Legal Compliance: If required by law or to protect the rights, safety, and property of our users."
              ]} />
            </div>
          </PolicySection>

          {/* Data Security */}
          <PolicySection
            title="Data Security"
            icon={
              <svg className="w-6 h-6 text-pink-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            }
          >
            <div className="p-4 bg-gradient-to-r from-pink-50 to-purple-50 rounded-lg">
              <p>
                We employ industry-standard security measures, including encryption and access controls, to protect your data 
                from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is 100% secure.
              </p>
            </div>
          </PolicySection>

          {/* User Rights */}
          <PolicySection
            title="User Rights"
            icon={
              <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          >
            <div className="space-y-4">
              <p>You have the right to:</p>
              <BulletList items={[
                "Access and review your personal information.",
                "Request corrections to inaccurate or incomplete data.",
                "Request the deletion of your personal data, subject to institutional approval."
              ]} />
            </div>
          </PolicySection>

          {/* Children's Privacy */}
          <PolicySection
            title="Children's Privacy"
            icon={
              <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            }
          >
            <div className="p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg">
              <p>
                PaperAI is designed for educational use and complies with children's privacy protection laws, such as COPPA and GDPR. 
                Schools are responsible for obtaining parental consent where required.
              </p>
            </div>
          </PolicySection>

          {/* Account Management */}
          <PolicySection
            title="Account Management"
            icon={
              <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            }
          >
            <div className="p-4 bg-gradient-to-r from-purple-50 to-pink-50 rounded-lg">
              <p>
                User accounts are created and managed by schools. If you wish to delete or modify your account, please contact your educational institution.
              </p>
            </div>
          </PolicySection>

          {/* Changes to This Privacy Policy */}
          <PolicySection
            title="Changes to This Privacy Policy"
            icon={
              <svg className="w-6 h-6 text-pink-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            }
          >
            <div className="p-4 bg-gradient-to-r from-pink-50 to-purple-50 rounded-lg">
              <p>
                We may update this Privacy Policy periodically. Any changes will be communicated through our website and app. 
                Continued use of PaperAI after changes constitutes acceptance of the updated policy.
              </p>
            </div>
          </PolicySection>

          {/* Contact Information */}
          <PolicySection
            title="Contact Us"
            icon={
              <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            }
          >
            <div className="bg-gradient-to-r from-indigo-100 to-purple-100 p-6 rounded-lg">
              <p className="mb-4">If you have any questions or concerns about this Privacy Policy, please reach out to us:</p>
              <div className="space-y-2">
                <p className="flex items-center gap-2">
                  <svg className="w-4 h-4 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="font-semibold text-indigo-700">Email:</span> 
                  <span className="text-gray-600">support@paperai.in</span>
                </p>
                <p className="flex items-center gap-2">
                  <svg className="w-4 h-4 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                  <span className="font-semibold text-purple-700">Website:</span>
                  <span className="text-gray-600">www.paperai.in</span>
                </p>
              </div>
            </div>
          </PolicySection>
        </div>

        {/* Footer */}
        <div className="bg-white rounded-lg shadow-lg p-4 mt-8 text-center">
          <p className="text-gray-500 text-sm">
            By using PaperAI, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;