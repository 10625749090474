import React, { useState } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/api';
import icon from '../img/icon.png';

const LoginModal = ({ open, onClose }) => {
  const [credentials, setCredentials] = useState({ identifier: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await authService.login(credentials.identifier, credentials.password);
      if (response.role === 'Staff' || response.role === 'Administrator' || response.role === 'student') {
        navigate('/dashboard');
      } else {
        setError('Invalid role');
      }
    } catch (err) {
      setError(err.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="login-modal"
      aria-describedby="login-modal-description"
      disableEnforceFocus
      disableAutoFocus
      keepMounted
      closeAfterTransition
      sx={{
        touchAction: 'manipulation',
        '& .MuiBackdrop-root': {
          touchAction: 'none'
        }
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: 400 },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        maxHeight: '90vh',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        '-webkit-tap-highlight-color': 'transparent',
      }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            component="img"
            src={icon}
            alt="Logo"
            sx={{
              width: 80,
              height: 80,
              mb: 2,
            }}
          />
          
          <Typography 
            component="h1" 
            variant="h5"
            sx={{
              background: 'linear-gradient(45deg, #6366F1, #EC4899, #EF4444)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              fontWeight: 'bold',
              mb: 3,
            }}
          >
            Welcome Back!
          </Typography>

          <TextField
            required
            fullWidth
            id="identifier-field"
            label="Email/Username"
            name="identifier"
            value={credentials.identifier}
            onChange={handleChange}
            error={!!error}
            type="text"
            inputMode="text"
            autoComplete="username"
            autoFocus={false}
            onFocus={(e) => {
              if (e && e.currentTarget) {
                try {
                  e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } catch (error) {
                  console.log('Scroll error:', error);
                }
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#6366F1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6366F1',
                },
              },
              '& .MuiInputBase-input': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none',
                '-webkit-tap-highlight-color': 'transparent',
              }
            }}
          />

          <TextField
            required
            fullWidth
            id="password-field"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={credentials.password}
            onChange={handleChange}
            error={!!error}
            inputMode="text"
            autoComplete="current-password"
            autoFocus={false}
            onFocus={(e) => {
              if (e && e.currentTarget) {
                try {
                  e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } catch (error) {
                  console.log('Scroll error:', error);
                }
              }
            }}
            onClick={(e) => {
              if (e && e.target) {
                e.target.focus();
              }
            }}
            InputProps={{
              readOnly: false,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={{ 
                      padding: { xs: '12px', sm: '8px' },
                      '-webkit-tap-highlight-color': 'transparent'
                    }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#6366F1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6366F1',
                },
              },
              '& .MuiInputBase-input': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none',
                '-webkit-tap-highlight-color': 'transparent',
                WebkitTextFillColor: 'currentcolor',
              }
            }}
          />

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            onClick={(e) => {
              if (!loading) {
                handleSubmit(e);
              }
            }}
            sx={{
              mt: 2,
              py: { xs: 1.8, sm: 1.5 },
              minHeight: { xs: '48px', sm: '42px' },
              background: 'linear-gradient(45deg, #6366F1, #EC4899)',
              transition: 'transform 0.2s ease', // Shortened transition
              '-webkit-tap-highlight-color': 'transparent',
              '&:hover': {
                background: 'linear-gradient(45deg, #4F46E5, #DB2777)',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(99, 102, 241, 0.4)',
              },
              '&:active': {
                transform: 'translateY(0)',
                transition: 'transform 0.1s ease', // Quick reset
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;
