import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Collapse,
  Container
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const faqData = [
    {
      question: "How accurate is the AI paper correction?",
      answer: "Our AI system achieves over 95% accuracy in identifying and correcting errors while maintaining context. It's continuously learning and improving through machine learning algorithms."
    },
    {
      question: "Can I track multiple subjects for each student?",
      answer: "Yes, our platform allows comprehensive tracking across all subjects, providing detailed analytics and progress reports for each subject individually."
    },
    {
      question: "Is the AI tutoring available 24/7?",
      answer: "Yes, our AI tutoring system is available round the clock to help students with their queries and provide personalized learning assistance."
    }
  ];

  const handleClick = (index) => {
    setOpenIndex(current => current === index ? null : index);
  };

  const handleMouseEnter = (index) => {
    if (openIndex === null) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const isExpanded = (index) => {
    return openIndex === index || (hoveredIndex === index && openIndex === null);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* <Box sx={{
        width: '100%',
        py: 1,
        background: 'rgba(255, 255, 255,0)',
      }}>
      </Box> */}

      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
          Frequently Asked{' '}
          <Box component="span" sx={{ color: 'primary.main' }}>
            Questions
          </Box>
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {faqData.map((faq, index) => (
          <Card
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            sx={{
              background: 'linear-gradient(135deg, #f3e8ff 0%, #e6f3ff 100%)',
              border: '1px solid rgba(147, 51, 234, 0.1)',
              boxShadow: 'none',
              borderRadius: 2,
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box
              onClick={() => handleClick(index)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'rgba(147, 51, 234, 0.05)',
                },
                transition: 'background-color 0.2s',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                }}
              >
                {faq.question}
              </Typography>
              <IconButton
                size="small"
                sx={{
                  color: 'primary.main',
                  transform: isExpanded(index) ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.2s',
                }}
              >
                <ExpandLessIcon />
              </IconButton>
            </Box>

            <Collapse in={isExpanded(index)}>
              <CardContent 
                sx={{ 
                  bgcolor: 'transparent',
                  transition: 'all 0.3s ease',
                  opacity: isExpanded(index) ? 1 : 0,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: 'text.secondary',
                    py: 1,
                    lineHeight: 1.7,
                  }}
                >
                  {faq.answer}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default FAQ;