import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  alpha,
  Divider,
  Grid
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ScatterChart,
  Scatter
} from 'recharts';
import { sub } from 'framer-motion/client';

const StudentGraphsModern = ({ subjectData, students }) => {
  const [selectedView, setSelectedView] = useState('performance');
  const theme = useTheme();

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setSelectedView(newView);
    }
  };

  const processPerformanceData = () => {
    const subjectAverages = {};
    let totalStudents = students.length;

    students.forEach(student => {
      const studentSubjects = subjectData[student.name] || [];
      studentSubjects.forEach(subject => {
        if (subject.status === 'corrected') {
          if (!subjectAverages[subject.subject_name]) {
            subjectAverages[subject.subject_name] = {
              name: subject.subject_name,
              totalMarks: 0,
              count: 0,
              above90: 0,
              above75: 0,
              above60: 0,
              above40: 0,
              below40: 0,
            };
          }
          
          const percentage = (subject.marks_obtained / subject.total_marks) * 100;
          subjectAverages[subject.subject_name].totalMarks += percentage;
          subjectAverages[subject.subject_name].count += 1;

          if (percentage >= 90) subjectAverages[subject.subject_name].above90 += 1;
          else if (percentage >= 75) subjectAverages[subject.subject_name].above75 += 1;
          else if (percentage >= 60) subjectAverages[subject.subject_name].above60 += 1;
          else if (percentage >= 40) subjectAverages[subject.subject_name].above40 += 1;
          else subjectAverages[subject.subject_name].below40 += 1;
        }
      });
    });

    return Object.values(subjectAverages).map(subject => ({
      ...subject,
      average: subject.count > 0 ? subject.totalMarks / subject.count : 0,
      above90Percent: (subject.above90 / totalStudents) * 100,
      above75Percent: (subject.above75 / totalStudents) * 100,
      above60Percent: (subject.above60 / totalStudents) * 100,
      above40Percent: (subject.above40 / totalStudents) * 100,
      below40Percent: (subject.below40 / totalStudents) * 100,
    }));
  };

  const processSkillsData = () => {
    // For testing, let's log the data structure
    // console.log('Students:', students);
    // console.log('SubjectData:', subjectData);

    // Initialize with test data to verify graph works
    const testData = [
      { subject: 'Mathematics', score: 85, fullMark: 100, studentCount: 20, totalMarks: 1700, totalMaxMarks: 2000 },
      { subject: 'Physics', score: 75, fullMark: 100, studentCount: 18, totalMarks: 1350, totalMaxMarks: 1800 },
      { subject: 'Chemistry', score: 90, fullMark: 100, studentCount: 19, totalMarks: 1710, totalMaxMarks: 1900 },
      { subject: 'Biology', score: 70, fullMark: 100, studentCount: 17, totalMarks: 1190, totalMaxMarks: 1700 },
      { subject: 'English', score: 80, fullMark: 100, studentCount: 20, totalMarks: 1600, totalMaxMarks: 2000 }
    ];

    // Initialize subject data structure
    const subjectStats = {};

    // Process student data
    students.forEach(student => {
      const studentSubjects = subjectData[student.name] || [];
      studentSubjects.forEach(subject => {
        if (subject.subject_name && subject.status === 'corrected') {
          const subjectName = subject.subject_name;
          
          if (!subjectStats[subjectName]) {
            subjectStats[subjectName] = {
              totalMarks: 0,
              totalMaxMarks: 0,
              studentCount: 0
            };
          }

          let studentTotal = 0;
          let studentMaxTotal = 0;

          // console.log("🚀 ~ Object.values ~ subject:", subject)
          // Calculate total marks for this student in this subject
          // Object.values(subject.marks_info).forEach(mark => {
          //   if (mark && typeof mark === 'object') {
          //     const score = Number(mark.marks_obtained) || 0;
          //     const maxScore = Number(mark.total_marks) || 0;
          //     studentTotal += score;
          //     studentMaxTotal += maxScore;
          //   }
          // });
          studentTotal = subject.marks_obtained;
          studentMaxTotal = subject.total_marks;

          if (studentMaxTotal > 0) {
            subjectStats[subjectName].totalMarks += studentTotal;
            subjectStats[subjectName].totalMaxMarks += studentMaxTotal;
            subjectStats[subjectName].studentCount++;
          }
        } else {
          if (!subjectStats[subject.subject_name]) {
            subjectStats[subject.subject_name] = {
              totalMarks: 0,
              totalMaxMarks: 0,
              studentCount: 0
            };
          }
          subjectStats[subject.subject_name].totalMarks = 0;
          subjectStats[subject.subject_name].totalMaxMarks = 0;
          subjectStats[subject.subject_name].studentCount = 0;
        }
      });
    });

    // Convert stats to chart data format
    const chartData = Object.entries(subjectStats)
      // .filter(([_, stats]) => stats.studentCount > 0)
      .map(([subject, stats]) => ({
        subject,
        score: Math.round((stats.totalMarks / stats.totalMaxMarks) * 100),
        fullMark: 100,
        studentCount: stats.studentCount,
        totalMarks: Math.round(stats.totalMarks),
        totalMaxMarks: Math.round(stats.totalMaxMarks)
      }));

    // Use test data if no real data is available
    return chartData.length > 0 ? chartData : testData;
  };

  const processDistributionData = () => {
    const ranges = [
      { name: '90-100%', range: [90, 100], count: 0, color: theme.palette.success.dark },
      { name: '75-89%', range: [75, 89], count: 0, color: theme.palette.success.main },
      { name: '60-74%', range: [60, 74], count: 0, color: theme.palette.info.main },
      { name: '40-59%', range: [40, 59], count: 0, color: theme.palette.warning.main },
      { name: 'Below 40%', range: [0, 39], count: 0, color: theme.palette.error.main },
    ];

    students.forEach(student => {
      const studentSubjects = subjectData[student.name] || [];
      studentSubjects.forEach(subject => {
        if (subject.status === 'corrected') {
          const percentage = (subject.marks_obtained / subject.total_marks) * 100;
          const range = ranges.find(r => 
            percentage >= r.range[0] && percentage <= r.range[1]
          );
          if (range) {
            range.count += 1;
          }
        }
      });
    });

    return ranges;
  };

  // const processProgressData = () => {
  //   // Sample data for progress over time
  //   return [
  //     { month: 'Jan', average: 75, highest: 95, lowest: 55 },
  //     { month: 'Feb', average: 78, highest: 96, lowest: 60 },
  //     { month: 'Mar', average: 82, highest: 98, lowest: 65 },
  //     { month: 'Apr', average: 80, highest: 97, lowest: 62 },
  //     { month: 'May', average: 85, highest: 100, lowest: 70 },
  //   ];
  // };

  const processProgressData = () => {
    const subjectStats = {};
    const subjectNames = []
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];

    students.forEach(student => {
      const studentSubjects = subjectData[student.name] || [];
      studentSubjects.forEach(subject => {
        if (subject.status === 'corrected') {
          const subjectName = subject.subject_name;
          const percentage = (subject.marks_obtained / subject.total_marks) * 100;
          subjectNames.push(subjectName);

          if (!subjectStats[subjectName]) {
            subjectStats[subjectName] = {
              scores: [],
              highest: -Infinity,
              lowest: Infinity,
              average: 0
            };
          }

          subjectStats[subjectName].scores.push(percentage);
          subjectStats[subjectName].highest = Math.max(subjectStats[subjectName].highest, percentage);
          subjectStats[subjectName].lowest = Math.min(subjectStats[subjectName].lowest, percentage);
        }
      });
    });

    // Calculate average for each subject
    Object.keys(subjectStats).forEach(subject => {
      const scores = subjectStats[subject].scores;
      subjectStats[subject].average = scores.length > 0 
        ? scores.reduce((a, b) => a + b, 0) / scores.length 
        : 0;
    });

    // If no data, return mock data
    if (Object.keys(subjectStats).length === 0) {
      return subjectNames.map((month, index) => ({
        subjectNames,
        highest: 95 - index * 5,
        average: 75 - index * 5,
        lowest: 55 - index * 5
      }));
    }

    // Transform data for line chart
    return subjectNames.map((subject_Name, index) => {
      const subjectNames = Object.keys(subjectStats);
      const subjectName = subjectNames[index % subjectNames.length];
      const stats = subjectStats[subjectName];

      return {
        subject_Name,
        highest: Math.round(stats.highest),
        lowest: Math.round(stats.lowest),
        average: Math.round(stats.average)
      };
    });
  };

  const processScatterData = () => {
    const subjectPassRatios = {};

    students.forEach(student => {
      const studentSubjects = subjectData[student.name] || [];
      studentSubjects.forEach(subject => {
        if (subject.status === 'corrected') {
          const subjectName = subject.subject_name;
          const percentage = (subject.marks_obtained / subject.total_marks) * 100;

          if (!subjectPassRatios[subjectName]) {
            subjectPassRatios[subjectName] = {
              totalStudents: 0,
              passedStudents: 0
            };
          }

          subjectPassRatios[subjectName].totalStudents++;
          // Assuming pass percentage is 40%
          if (percentage >= 40) {
            subjectPassRatios[subjectName].passedStudents++;
          }
        }
      });
    });

    // If no data, return mock data
    if (Object.keys(subjectPassRatios).length === 0) {
      return [
        { subject: 'Mathematics', score: 85, student: 'Student 1' },
        { subject: 'Physics', score: 75, student: 'Student 2' },
        { subject: 'Chemistry', score: 90, student: 'Student 3' },
        { subject: 'Biology', score: 70, student: 'Student 4' },
        { subject: 'English', score: 80, student: 'Student 5' },
      ];
    }

    // Calculate pass ratios
    const passRatioData = Object.entries(subjectPassRatios).map(([subject, stats]) => ({
      subject,
      score: stats.totalStudents > 0 
        ? Math.round((stats.passedStudents / stats.totalStudents) * 100) 
        : 0,
      totalStudents: stats.totalStudents,
      passedStudents: stats.passedStudents,
      student: `Pass Ratio: ${stats.passedStudents}/${stats.totalStudents}`
    }));

    return passRatioData;
  };

  // const processScatterData = () => {
  //   const data = [];
  //   students.forEach(student => {
  //     const studentSubjects = subjectData[student.name] || [];
  //     studentSubjects.forEach(subject => {
  //       if (subject.status === 'corrected' && subject.marks_info) {
  //         let totalMarks = 0;
  //         let maxMarks = 0;
  //         Object.values(subject.marks_info).forEach(mark => {
  //           if (mark && typeof mark === 'object') {
  //             totalMarks += Number(mark.score) || 0;
  //             maxMarks += Number(mark.maxScore) || 0;
  //           }
  //         });
  //         if (maxMarks > 0) {
  //           data.push({
  //             subject: subject.subject_name,
  //             score: Math.round((totalMarks / maxMarks) * 100),
  //             student: student.name
  //           });
  //         }
  //       }
  //     });
  //   });
  //   return data.length > 0 ? data : [
  //     { subject: 'Mathematics', score: 85, student: 'Student 1' },
  //     { subject: 'Physics', score: 75, student: 'Student 2' },
  //     { subject: 'Chemistry', score: 90, student: 'Student 3' },
  //     { subject: 'Biology', score: 70, student: 'Student 4' },
  //     { subject: 'English', score: 80, student: 'Student 5' },
  //   ];
  // };

  const renderPerformanceGraph = () => {
    const data = processPerformanceData();
    const colors = [
      theme.palette.success.dark,
      theme.palette.success.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ];

    // Calculate container height based on data
    const minHeight = 250;
    const heightPerItem = 40;
    const height = Math.max(minHeight, Math.min(300, data.length * heightPerItem));

    return (
      <Box sx={{ width: '100%', height: height }}>
        <ResponsiveContainer>
          <BarChart 
            data={data} 
            margin={{ top: 10, right: 30, left: 20, bottom: 20 }}
            barSize={60}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke={alpha(theme.palette.text.primary, 0.1)}
              vertical={false}
            />
            <XAxis 
              dataKey="name" 
              tick={{ 
                fill: theme.palette.text.primary,
                fontSize: 12,
              }}
              axisLine={{ stroke: theme.palette.divider }}
              tickLine={false}
            />
            <YAxis 
              tick={{ 
                fill: theme.palette.text.primary,
                fontSize: 12,
              }}
              axisLine={{ stroke: theme.palette.divider }}
              tickLine={false}
              unit="%"
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: theme.shadows[2],
                fontSize: 12,
              }}
              cursor={{ fill: alpha(theme.palette.primary.main, 0.1) }}
              formatter={(value) => [`${value.toFixed(1)}%`, '']}
            />
            <Legend 
              wrapperStyle={{ 
                paddingTop: 10,
                fontSize: 12,
              }}
              iconType="circle"
            />
            <Bar dataKey="above90Percent" name="Above 90%" stackId="a" fill={colors[0]} radius={[4, 4, 0, 0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fillOpacity={0.8} />
              ))}
            </Bar>
            <Bar dataKey="above75Percent" name="75-90%" stackId="a" fill={colors[1]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fillOpacity={0.8} />
              ))}
            </Bar>
            <Bar dataKey="above60Percent" name="60-75%" stackId="a" fill={colors[2]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fillOpacity={0.8} />
              ))}
            </Bar>
            <Bar dataKey="above40Percent" name="40-60%" stackId="a" fill={colors[3]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fillOpacity={0.8} />
              ))}
            </Bar>
            <Bar dataKey="below40Percent" name="Below 40%" stackId="a" fill={colors[4]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fillOpacity={0.8} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderSkillsGraph = () => {
    const data = processSkillsData();
    console.log('Radar Chart Data:', data); // For debugging

    const colors = {
      area: theme.palette.primary.main,
      grid: alpha(theme.palette.divider, 0.5),
      text: theme.palette.text.primary
    };
    
    return (
      <Box sx={{ width: '100%', height: 400, position: 'relative' }}>
        <ResponsiveContainer>
          <RadarChart 
            cx="50%" 
            cy="50%" 
            outerRadius="70%" 
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <PolarGrid 
              stroke={colors.grid}
              gridType="polygon"
            />
            <PolarAngleAxis 
              dataKey="subject"
              tick={{ 
                fill: colors.text,
                fontSize: 12,
                fontWeight: 500
              }}
              stroke={colors.grid}
            />
            <PolarRadiusAxis 
              angle={30}
              domain={[0, 100]}
              tick={{ 
                fill: theme.palette.text.secondary,
                fontSize: 11
              }}
              tickCount={5}
              stroke={colors.grid}
              axisLine={true}
            />
            <Radar
              name="Class Performance"
              dataKey="score"
              stroke={colors.area}
              fill={colors.area}
              fillOpacity={0.5}
              dot={{
                fill: colors.area,
                strokeWidth: 2,
                r: 4,
                strokeOpacity: 1,
                stroke: theme.palette.background.paper
              }}
              activeDot={{
                r: 6,
                strokeWidth: 2,
                stroke: theme.palette.background.paper
              }}
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: theme.shadows[2],
                padding: '12px'
              }}
              formatter={(value, name, props) => [
                <Box>
                  <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 600 }}>
                    {props.payload.subject}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                    {value}% Average Score
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Total Marks: {props.payload.totalMarks} / {props.payload.totalMaxMarks}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Students: {props.payload.studentCount}
                  </Typography>
                </Box>,
                ''
              ]}
            />
            <Legend 
              wrapperStyle={{
                paddingTop: 20,
                fontSize: 12
              }}
            />
          </RadarChart>
          
        </ResponsiveContainer>
        <Box 
          sx={{ 
            position: 'absolute',
            top: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Typography 
            variant="subtitle1"
            sx={{ 
              fontSize: 14,
              fontWeight: 600,
              color: theme.palette.text.primary
            }}
          >
            Subject-wise Class Performance
          </Typography>
          <Typography 
            variant="body2"
            sx={{ 
              fontSize: 11,
              color: theme.palette.text.secondary,
              mt: 0.5
            }}
          >
            Average scores across all students
          </Typography>
        </Box>
      </Box>
    );
  };

  // const renderProgressGraph = () => {
  //   const data = processProgressData();
    
  //   return (
  //     <Box sx={{ width: '100%', height: 300, position: 'relative', mt: 3 }}>
  //       <Typography variant="h6" sx={{ mb: 2, fontSize: 14, fontWeight: 600 }}>
  //         Performance Trend
  //       </Typography>
  //       <ResponsiveContainer>
  //         <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
  //           <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
  //           <XAxis 
  //             dataKey="month" 
  //             tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
  //             stroke={theme.palette.divider}
  //           />
  //           <YAxis 
  //             tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
  //             stroke={theme.palette.divider}
  //             unit="%"
  //           />
  //           <Tooltip
  //             contentStyle={{
  //               backgroundColor: theme.palette.background.paper,
  //               border: `1px solid ${theme.palette.divider}`,
  //               borderRadius: 1,
  //               boxShadow: theme.shadows[2],
  //             }}
  //           />
  //           <Legend />
  //           <Line 
  //             type="monotone" 
  //             dataKey="highest" 
  //             stroke={theme.palette.success.main} 
  //             strokeWidth={2}
  //             dot={{ fill: theme.palette.success.main }}
  //             name="Highest Score"
  //           />
  //           <Line 
  //             type="monotone" 
  //             dataKey="average" 
  //             stroke={theme.palette.primary.main} 
  //             strokeWidth={2}
  //             dot={{ fill: theme.palette.primary.main }}
  //             name="Average Score"
  //           />
  //           <Line 
  //             type="monotone" 
  //             dataKey="lowest" 
  //             stroke={theme.palette.error.main} 
  //             strokeWidth={2}
  //             dot={{ fill: theme.palette.error.main }}
  //             name="Lowest Score"
  //           />
  //         </LineChart>
  //       </ResponsiveContainer>
  //     </Box>
  //   );
  // };

  const renderProgressGraph = () => {
    const data = processProgressData();
    
    return (
      <Box sx={{ width: '100%', height: 300, position: 'relative', mt: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, fontSize: 14, fontWeight: 600 }}>
          Performance Trend
        </Typography>
        <ResponsiveContainer>
          <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
            <XAxis 
              dataKey="subject_Name" 
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              stroke={theme.palette.divider}
            />
            <YAxis 
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              stroke={theme.palette.divider}
              unit="%"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: theme.shadows[2],
              }}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="highest" 
              stroke={theme.palette.success.main} 
              strokeWidth={2}
              dot={{ fill: theme.palette.success.main }}
              name="Highest Score"
            />
            <Line 
              type="monotone" 
              dataKey="average" 
              stroke={theme.palette.primary.main} 
              strokeWidth={2}
              dot={{ fill: theme.palette.primary.main }}
              name="Average Score"
            />
            <Line 
              type="monotone" 
              dataKey="lowest" 
              stroke={theme.palette.error.main} 
              strokeWidth={2}
              dot={{ fill: theme.palette.error.main }}
              name="Lowest Score"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderScatterGraph = () => {
    const data = processScatterData();
    
    return (
      <Box sx={{ width: '100%', height: 300, position: 'relative', mt: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, fontSize: 14, fontWeight: 600 }}>
          Score Distribution
        </Typography>
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
            <XAxis 
              dataKey="subject" 
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              stroke={theme.palette.divider}
            />
            <YAxis 
              dataKey="score" 
              unit="%" 
              domain={[0, 100]}
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              stroke={theme.palette.divider}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: theme.shadows[2],
              }}
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name, props) => {
                const totalStudents = props.payload.totalStudents;
                const passedStudents = props.payload.passedStudents;
                return totalStudents 
                  ? [`${value}% (${passedStudents}/${totalStudents})`, name]
                  : [value, name];
              }}
            />
            <Scatter 
              data={data} 
              fill={theme.palette.primary.main}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  // const renderScatterGraph = () => {
  //   const data = processScatterData();
    
  //   return (
  //     <Box sx={{ width: '100%', height: 300, position: 'relative', mt: 3 }}>
  //       <Typography variant="h6" sx={{ mb: 2, fontSize: 14, fontWeight: 600 }}>
  //         Score Distribution
  //       </Typography>
  //       <ResponsiveContainer>
  //         <ScatterChart margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
  //           <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
  //           <XAxis 
  //             dataKey="subject" 
  //             tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
  //             stroke={theme.palette.divider}
  //           />
  //           <YAxis 
  //             dataKey="score" 
  //             unit="%" 
  //             domain={[0, 100]}
  //             tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
  //             stroke={theme.palette.divider}
  //           />
  //           <Tooltip
  //             contentStyle={{
  //               backgroundColor: theme.palette.background.paper,
  //               border: `1px solid ${theme.palette.divider}`,
  //               borderRadius: 1,
  //               boxShadow: theme.shadows[2],
  //             }}
  //             cursor={{ strokeDasharray: '3 3' }}
  //           />
  //           <Scatter 
  //             data={data} 
  //             fill={theme.palette.primary.main}
  //           />
  //         </ScatterChart>
  //       </ResponsiveContainer>
  //     </Box>
  //   );
  // };

  const renderDistributionGraph = () => {
    const data = processDistributionData();
    const RADIAN = Math.PI / 180;
    const totalCount = data.reduce((sum, item) => sum + item.count, 0);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      if (percent < 0.05) return null;

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize={11}
          fontWeight="500"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
      <Box sx={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              innerRadius={60}
              dataKey="count"
              animationBegin={0}
              animationDuration={1000}
            >
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={entry.color}
                  fillOpacity={0.8}
                  strokeWidth={1}
                  stroke={theme.palette.background.paper}
                />
              ))}
            </Pie>
            <Tooltip 
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: theme.shadows[2],
                padding: '8px 12px',
                fontSize: 12,
              }}
              formatter={(value, name, props) => {
                const entry = data.find(item => item.name === name);
                return [
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: entry?.color || theme.palette.text.primary }}>
                      {name}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                      {value} students ({((value/totalCount) * 100).toFixed(1)}%)
                    </Typography>
                  </Box>,
                  ''
                ];
              }}
            />
            <Legend 
              verticalAlign="middle"
              align="right"
              layout="vertical"
              iconType="circle"
              wrapperStyle={{
                fontSize: 12,
                paddingLeft: 20,
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Student Performance Analytics
          </Typography>
          {/* Wrapper for horizontal scrolling on mobile */}
          <Box
            sx={{
              overflowX: 'auto', // Enable horizontal scrolling
              whiteSpace: 'nowrap', // Prevent wrapping of buttons
              mb: 2,
              '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar for a cleaner look
              },
            }}
          >
            <ToggleButtonGroup
              value={selectedView}
              exclusive
              onChange={handleViewChange}
              aria-label="graph view"
              sx={{
                display: 'inline-flex', // Ensure buttons are in a single row
                flexWrap: 'nowrap', // Prevent wrapping
              }}
            >
              <ToggleButton 
                value="performance" 
                aria-label="performance view"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Performance
              </ToggleButton>
              <ToggleButton 
                value="skills" 
                aria-label="skills view"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                Skills
              </ToggleButton>
              <ToggleButton 
                value="trends" 
                aria-label="trends view"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha(theme.palette.success.main, 0.1),
                    color: theme.palette.success.main,
                  },
                }}
              >
                Trends
              </ToggleButton>
              <ToggleButton 
                value="distribution" 
                aria-label="distribution view"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha(theme.palette.info.main, 0.1),
                    color: theme.palette.info.main,
                  },
                }}
              >
                Distribution
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
  
        {selectedView === 'performance' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderPerformanceGraph()}
            </Grid>
          </Grid>
        )}
  
        {selectedView === 'skills' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderSkillsGraph()}
            </Grid>
          </Grid>
        )}
  
        {selectedView === 'trends' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderProgressGraph()}
            </Grid>
            <Grid item xs={12}>
              {renderScatterGraph()}
            </Grid>
          </Grid>
        )}
  
        {selectedView === 'distribution' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderDistributionGraph()}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );

};

export default StudentGraphsModern;
