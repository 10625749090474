import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FeatureSection from './components/FeatureSection';
import Intro from './components/Intro';
import Privacy from './pages/Privacy';

// Import dashboard component
import Dashboard from './pages/DashboardModern';

const PrivacyPage = () => {
  return(
    <>
      <Header />
      <Privacy />
      <Footer />
    </>
  )
}

const LandingPage = () => {
  return (
    <>
      <Header />
      <div id="intro">
        <Intro />
      </div>
      <div id="features">
        <FeatureSection />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <div className="min-h-screen">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path = "/privacy" element={ <PrivacyPage/>} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;