import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { scrollToSection } from '../utils/scrollUtils';

// Import all images
import paperCorrectionImage from '../img/new/image2.png';
import attendanceImage from '../img/new/image3.1.png';
import analyticsImage from '../img/new/image4.png';
import tutoringImage from '../img/new/image5.png';
import dashboardImage from '../img/new/image6.png';

const sections = [
  {
    title: "Smart Paper Correction",
    description: "Advanced AI-powered system for contextual correction, grammar checking, and spelling mistake detection. Our technology understands the context and provides accurate corrections while maintaining the original meaning.",
    image: paperCorrectionImage,
  },
  {
    title: 'Smart Attendance System',
    description: 'Track individual student progress across subjects with detailed analytics. Visualize growth patterns, identify areas of improvement, and get personalized recommendations for each student.',
    image: attendanceImage, 
  },
  {
    title: 'Student Growth Analytics',
    description: 'Track individual student progress across subjects with detailed analytics. Visualize growth patterns, identify areas of improvement, and get personalized recommendations for each student.',
    image: analyticsImage, 
  },
  {
    title: 'AI Tutoring Assistant',
    description: '24/7 personalized AI tutor that adapts to each student\'s learning style. Provides instant help, explanations, and practice problems tailored to individual needs.',
    image: tutoringImage,  
  },
  {
    title: 'Teacher Dashboard',
    description: 'Track individual student progress across subjects with detailed analytics. Visualize growth patterns, identify areas of improvement, and get personalized recommendations for each student.',
    image: dashboardImage, 
  }
];

const ContentSection = ({ title, description, image, index }) => {

  const scrollId = 'contact';

  const handleLearnMore = () => {
    scrollToSection(scrollId);
  }

  const isImageLeft = (index+1) % 2 === 0;

  const bgcolor = 'linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgb(248, 204, 255) 50%, rgb(189, 209, 255) 100%)';
  const backgroundColor = isImageLeft ? bgcolor : 'transparent';


  // const bgcolorLeft = 'linear-gradient(90deg, rgba(255,255,255,1) 20%, rgb(248, 204, 255) 50%, rgb(189, 209, 255) 100%)';
  // const bgcolorRight = 'linear-gradient(90deg, rgba(189, 209, 255,1) 20%, rgb(248, 204, 255) 50%, rgb(255,255,255) 100%)';
  // const backgroundColor = isImageLeft ? bgcolorLeft : bgcolorRight;

  // const bgcolor = 'conic-gradient(from 90deg at 50% 100%, rgb(255, 255, 255) 0deg, rgb(206, 173, 250) 90deg, rgb(159, 215, 252) 180deg, rgb(191, 219, 254) 270deg)'
  // const bgcolor = 'conic-gradient(from 90deg at 50% 100%, rgb(255, 255, 255) 0deg, rgb(206, 173, 250) 90deg, rgb(159, 215, 252) 180deg, rgb(191, 219, 254) 270deg)'



  const ContentBlock = () => (
    <Box
      sx={{
        width: { xs: '100%', md: '60%' },
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        pl: { md: isImageLeft ? 10 : 0 },
        pr: { md: isImageLeft ? 0 : 4 },
        // left: isImageLeft ? '-90px' : 'auto',
        // right: isImageLeft ? 'auto' : '-90px',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: '36px', md: '52px' },
          fontWeight: 800,
          color: '#111827',
          lineHeight: 1.1,
          letterSpacing: '-0.02em',
          fontFamily: '"Inter", sans-serif',
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: '16px', md: '20px' },
          color: '#4B5563',
          lineHeight: 1.7,
          fontFamily: '"Inter", sans-serif',
          maxWidth: '90%'
        }}
      >
        {description}
      </Typography>

      <Button
        variant="contained"
        onClick={handleLearnMore}
        sx={{
          background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
          color: 'white',
          borderRadius: '9999px',
          padding: '16px 36px',
          textTransform: 'none',
          fontSize: '18px',
          fontWeight: 600,
          width: 'fit-content',
          boxShadow: 'none',
          transition: 'all 0.3s', 
          '&:hover': {
            background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
            transform: 'translate(-4px, -4px)',
            boxShadow: '4px 4px 0px black',
          },
        }}
      >
        Learn More
      </Button>
    </Box>
  );

  const ImageBlock = () => (
    <Box
    component="img"
    src={image}
    alt={`Feature ${index + 1}`}
    sx={{
      width: { xs: '100%', md: '45%' },
      height: '100%',
      borderRadius: '22px',
      display: 'block',
      objectFit: 'cover'
    }}
  >
  </Box>
  );

  return (
    <Box
      sx={{
        width: '100vw',
        position: 'relative',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        background: backgroundColor,
      }}
      
    >
      <Container 
        maxWidth={false}
        sx={{
          maxWidth: '1300px',
          px: { xs: 2, md: 4 }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: "100%",
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 4, md: 12 },
            flexDirection: { 
              xs: 'column', 
              md: isImageLeft ? 'row' : 'row-reverse' 
            },
            position: 'relative',
            py: { xs: 6, md: 10 },
          }}
        >
          <ImageBlock />
          <ContentBlock />
        </Box>
      </Container>
    </Box>
  );
};

const SmartPaperCorrection = () => {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      {sections.map((section, index) => (
        <ContentSection 
          key={index}
          {...section}
          index={index}
        />
      ))}
    </Box>
  );
};

export default SmartPaperCorrection;