import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, List, ListItem, Container, Divider } from '@mui/material';
import { Mail, MapPin, Phone } from 'lucide-react';
import { scrollToSection } from '../utils/scrollUtils';

import icon from '../img/icon.png';

const menuItems = [
  { label: 'Home', id: 'intro' },
  { label: 'Services', id: 'features' },
  { label: 'FAQ', id: 'faq' },
  { label: 'Contact', id: 'contact' }
];

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    navigate('/privacy');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClick = (id) => {
    navigate('/');
    setTimeout(() => {
      scrollToSection(id);
    }, 1);
  };

  return (
    <Box>
      <Box sx={{
        width: '100%',
        py: 2,
        background: 'rgba(255, 255, 255,0)',
        }}>
      </Box>

      <Box component="footer" sx={{ bgcolor: 'rgb(0,0,0)', color: 'white' }}>
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 5 } }}>
        {/* Main Footer Content */}
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and About */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Box
                component="img"
                src={icon}
                alt="Logo"
                sx={{ width: 40, height: 40 }}
              />
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                PaperAI
              </Typography>
            </Box>
            <Typography sx={{
              color: 'rgba(255,255,255,0.7)',
              fontSize: '0.875rem',
              lineHeight: 1.6
            }}>
              {/* Empowering students with innovative AI tools for enhanced learning. */}
              PaperAI is an innovative educational technology platform that leverages AI to empower students with smart tools to enhance their academic journey.

            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={6} sm={6} md={2}>
            <Typography sx={{ 
              fontWeight: 600,
              fontSize: '1rem',
              mb: 2
            }}>
              Quick Links
            </Typography>
            <List sx={{ p: 0 }}>
              {menuItems.map((item) => (
                <ListItem key={item.label} sx={{ p: 0, mb: 1 }}>
                  <Box
                    component="button"
                    onClick={() => handleClick(item.id)}
                    sx={{
                      background: 'none',
                      border: 'none',
                      color: 'rgba(255,255,255,0.7)',
                      fontSize: '0.875rem',
                      cursor: 'pointer',
                      padding: 0,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        color: 'white',
                        transform: 'translateX(5px)'
                      }
                    }}
                  >
                    {item.label}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ 
              fontWeight: 600,
              fontSize: '1rem',
              mb: 2
            }}>
              Contact Us
            </Typography>
            <List sx={{ p: 0 }}>
              {[
                { Icon: Mail, text: 'support@paperai.in' },
                { Icon: MapPin, text: 'Bangalore, Karnataka' },
                { Icon: Phone, text: '+91 98841 47946' }
              ].map((item, index) => (
                <ListItem key={index} sx={{
                  p: 0,
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5
                }}>
                  <Box sx={{
                    width: 32,
                    height: 32,
                    bgcolor: 'rgba(255,255,255,0.05)',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <item.Icon size={16} />
                  </Box>
                  <Typography sx={{
                    color: 'rgba(255,255,255,0.7)',
                    fontSize: '0.875rem'
                  }}>
                    {item.text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Divider sx={{ 
          mt: 4, 
          mb: 3,
          borderColor: 'rgba(255,255,255,0.1)'
        }} />
        
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: { xs: 1.5, sm: 0 }
        }}>
          <Typography 
            sx={{ 
              color: 'rgba(255,255,255,0.7)',
              fontSize: '0.875rem',
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            © {currentYear} PaperAI. All Rights Reserved.
          </Typography>

          <Box
            component="a"
            onClick={handlePrivacyClick}
            sx={{
              color: 'rgba(255,255,255,0.7)',
              fontSize: '0.875rem',
              cursor: 'pointer',
              textDecoration: 'none',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'white'
              }
            }}
          >
            Privacy Policy
          </Box>
        </Box>
      </Container>
    </Box>
  </Box>
    
  );
};

export default Footer;