import React from 'react';
import { Box, Typography } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import icon from '../img/icon.png';

const Logo = ({ variant = 'default' }) => {
  const isDefault = variant === 'default';
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box 
        component="img"
        src={icon}
        sx={{ 
          width: { xs: '40px', sm: '50px', md: '60px' },
          height: { xs: '40px', sm: '50px', md: '60px' },
          fontSize: isDefault ? 40 : 32,
          color: 'primary.main',
          filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.2))'
        }} 
      />
      <Typography
        variant={isDefault ? "h4" : "h6"}
        component="span"
        sx={{
          fontWeight: 700,
          background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.2))',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Paper
        <Typography
          component="span"
          variant={isDefault ? "h4" : "h6"}
          sx={{
            fontWeight: 700,
            color: '#1565C0',
            ml: 0.5
          }}
        >
          AI
        </Typography>
      </Typography>
    </Box>
  );
};

export default Logo;
