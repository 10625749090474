import * as React from 'react';
import { Box, TextField, Button, Typography, IconButton, Drawer, List, ListItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { scrollToSection } from '../utils/scrollUtils';
import { useActiveSection } from '../hooks/useActiveSection';
import LoginModal from './LoginModal';
import { useNavigate } from 'react-router-dom';

import icon from '../img/icon.png';

const navItems = [
  { title: 'Home', id: 'intro' },
  { title: 'Services', id: 'features' },
  { title: "FAQ's", id: 'faq' },
  { title: 'Get In Touch', id: 'contact' },
];

const Header = () => {
  const [activeSection, setActiveSection] = useActiveSection(navItems.map(item => item.id));
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  
  const handleClick = (id) => {
    navigate('/');
    setTimeout(() => {
      scrollToSection(id);
    }, 1);
  };

  const handleLogin = () => {
    setLoginModalOpen(true);
    setMobileMenuOpen(false);
  };

  const MobileMenu = () => (
    <Drawer
      anchor="right"
      open={mobileMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '300px',
          bgcolor: 'white',
          p: 2
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <IconButton onClick={() => setMobileMenuOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {navItems.map((item) => (
          <ListItem 
            key={item.title}
            onClick={() => handleClick(item.id)}
            sx={{
              py: 2,
              color: activeSection === item.id ? '#7c3aed' : '#666',
              cursor: 'pointer',
              '&:hover': {
                color: '#7c3aed',
                bgcolor: 'rgba(124, 58, 237, 0.1)'
              }
            }}
          >
            <Typography variant="h6">{item.title}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ flexDirection: 'column', gap: 2, mt: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleLogin}
            sx={{
              borderRadius: '50px',
              py: 1,
              fontSize: { xs: '16px', sm: '18px' },
              borderColor: 'rgb(0, 0, 0)',
              color: 'rgb(0, 0, 0)',
              textTransform: 'none',
              transition: 'all 0.3s',
              '&:hover': {
                color: 'rgb(255,255, 255)',
                background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                transform: 'translate(-4px, -4px)',
                boxShadow: '4px 4px 0px black',
              },
            }}
          >
            Login
          </Button>
          {/* <Button
            fullWidth
            variant="contained"
            onClick={handleGetStarted}
            sx={{
              borderRadius: '50px',
              py: 1,
              fontSize: { xs: '16px', sm: '18px' },
              borderColor: 'rgb(0, 0, 0)',
              background: 'transparent',
              color: 'rgb(0, 0, 0)',
              textTransform: 'none',
              transition: 'all 0.3s',
              '&:hover': {
                color: 'rgb(255,255, 255)',
                background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                transform: 'translate(-4px, -4px)',
                boxShadow: '4px 4px 0px black',
              },
            }}
          >
            Get Started
          </Button> */}
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <Box sx={{
      width: '100%',
      height: { xs: '70px', md: '80px' },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      px: { xs: 2, sm: 4, md: 8 },
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      bgcolor: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
    }}>
      {/* Logo Section */}
      <Box 
        onClick={() => handleClick('intro')}
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)'
          }
        }}
      >
        <Box
          component="img"
          src={icon}
          alt="Paper Plane Logo"
          sx={{
            width: { xs: '50px', sm: '60px', md: '70px' },
            height: { xs: '50px', sm: '60px', md: '70px' },
            transform: 'rotate(-10deg)',
            filter: 'brightness(0.95)',
            ml: { xs: 0, sm: 2, md: 7 }
          }}
        />

        <Typography
          sx = {{
            color : 'rgb(0, 0, 0)',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.2rem' },
            fontWeight: 500,
            letterSpacing: '-0.02em',
            lineHeight: 1,
            transition: 'all 0.3s ease',
          }}
        >
          Paper AI
        </Typography>

      </Box>

      {/* Navigation Links - Desktop */}
      {!isMobile && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { sm: 4, md: 10 },
        }}>
          {navItems.map((item) => (
            <Typography
              key={item.title}
              onClick={() => handleClick(item.id)}
              sx={{
                fontSize: { sm: '1.2rem', md: '1.5rem' },
                fontWeight: 500,
                color: activeSection === item.id ? '#7c3aed' : '#666',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                position: 'relative',
                '&:hover': {
                  color: '#7c3aed',
                  transform: 'translateY(-2px)'
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -2,
                  left: 0,
                  width: activeSection === item.id ? '100%' : '0%',
                  height: '2px',
                  backgroundColor: '#7c3aed',
                  transition: 'width 0.3s ease'
                }
              }}
            >
              {item.title}
            </Typography>
          ))}
        </Box>
      )}

      {/* Login Buttons - Desktop */}
      {!isMobile ? (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={handleLogin}
            sx={{
              borderRadius: '50px',
              px: { sm: 2, md: 3 },
              py: 1,
              fontSize: { sm: '16px', md: '20px' },
              borderColor: 'rgb(0, 0, 0)',
              background: 'transparent',
              color: 'rgb(0, 0, 0)',
              textTransform: 'none',
              transition: 'all 0.3s',
              '&:hover': {
                color: 'rgb(255,255, 255)',
                background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                transform: 'translate(-4px, -4px)',
                boxShadow: '4px 4px 0px black',
              },
            }}
          >
            Login
          </Button>
          {/* <Button
            variant="contained"
            onClick={handleGetStarted}
            sx={{
              borderRadius: '50px',
              px: { sm: 2, md: 3 },
              py: 1,
              fontSize: { sm: '16px', md: '20px' },
              borderColor: 'rgb(0, 0, 0)',
              background: 'transparent',
              color: 'rgb(0, 0, 0)',
              textTransform: 'none',
              transition: 'all 0.3s',
              '&:hover': {
                color: 'rgb(255,255, 255)',
                background: 'linear-gradient(90deg, #7f00ff 0%, #e100ff 100%)',
                transform: 'translate(-4px, -4px)',
                boxShadow: '4px 4px 0px black',
              },
            }}
          >
            Get Started
          </Button> */}
        </Box>
      ) : (
        <IconButton 
          onClick={() => setMobileMenuOpen(true)}
          sx={{ color: '#666' }}
        >
          <MenuIcon />
        </IconButton>
      )}

      <MobileMenu />

      <LoginModal 
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </Box>
  );
};

export default Header;