import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Chip,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  GetApp as DownloadIcon,
  Print as PrintIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  AddCircleOutline as ZoomInIcon,
  RemoveCircleOutline as ZoomOutIcon,
} from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { teacherService } from '../services/api';

const MarksTableModern = ({ subjectData, students, selectedExam }) => {
  const theme = useTheme();
  const [processedData, setProcessedData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [answerSheets, setAnswerSheets] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (subjectData && students && students.length > 0) {
      const processed = processMarksData();
      setProcessedData(processed);
    }
  }, [subjectData, students]);

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.25, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.25, 0.5));
  };

  const processMarksData = () => {
    return students.map((student, index) => {
      const studentSubjects = subjectData[student.name] || [];
      let totalMarksObtained = 0;
      let totalPossibleMarks = 0;
      let completedExams = 0;

      if (Array.isArray(studentSubjects)) {
        studentSubjects.forEach(subject => {
          if (subject.status === 'corrected' && subject.marks_obtained !== undefined) {
            totalMarksObtained += subject.marks_obtained;
            totalPossibleMarks += subject.total_marks;
            completedExams++;
          }
        });
      }

      const avgPercentage = completedExams > 0 
        ? (totalMarksObtained / totalPossibleMarks) * 100 
        : 0;

      return {
        id: index + 1,
        ...student,
        subjects: studentSubjects,
        totalMarksObtained,
        totalPossibleMarks,
        completedExams,
        avgPercentage,
        grade: calculateGrade(avgPercentage)
      };
    });
  };

  const calculateGrade = (percentage) => {
    if (percentage >= 90) return 'A+';
    if (percentage >= 80) return 'A';
    if (percentage >= 70) return 'B';
    if (percentage >= 60) return 'C';
    if (percentage >= 50) return 'D';
    return 'F';
  };

  const handleViewAnswerSheet = async (student) => {
    try {
      setLoading(true);
      setSelectedStudent(student);
      setOpenDialog(true);
      
      // Get exam details and answer sheets for the student
      const examDetails = await teacherService.getStudentMarks(selectedExam, student.id);
      const studentSubjects = examDetails.subjects || [];
      const sheets = {};
      
      // Process each subject's answer sheets
      studentSubjects.forEach((subject) => {
        if (subject.status === 'corrected' && subject.paper_urls && subject.paper_urls.length > 0) {
          sheets[subject.subject_name] = {
            paper_urls: subject.paper_urls,
            marks_obtained: subject.marks_obtained,
            total_marks: subject.total_marks,
            feedback: subject.feedback,
            marks_info: subject.marks_info
          };
        }
      });
      
      // If no answer sheets found, show message
      if (Object.keys(sheets).length === 0) {
        setAnswerSheets({
          'No Answer Sheets': [
            { image_url: 'https://via.placeholder.com/800x1000?text=No+Answer+Sheets+Found' }
          ]
        });
      } else {
        setAnswerSheets(sheets);
      }
    } catch (error) {
      console.error('Failed to process answer sheets:', error);
      setAnswerSheets({
        'Error': [
          { image_url: 'https://via.placeholder.com/800x1000?text=Error+Loading+Answer+Sheets' }
        ]
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStudent(null);
    setAnswerSheets({});
    setSelectedSubject(0);
    setSelectedQuestion(null);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'corrected': return <CheckCircleIcon fontSize="small" color="success" />;
      case 'pending': return <WarningIcon fontSize="small" color="warning" />;
      case 'absent': return <ErrorIcon fontSize="small" color="error" />;
      default: return null;
    }
  };

  const renderMarksCell = (params) => {
    const subject = params.row.subjects.find(s => s.subject_name === params.field);
    if (!subject) return '-';
    
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {getStatusIcon(subject.status)}
        <Typography
          variant="body2"
          color={
            subject.status === 'corrected'
              ? (subject.marks_obtained >= subject.total_marks * 0.4) ? 'success.main' : 'error.main'
              : 'text.secondary'
          }
        >
          {subject.status === 'corrected' ? `${subject.marks_obtained}/${subject.total_marks}` : subject.status}
        </Typography>
      </Stack>
    );
  };

  const getColumns = () => {
    const baseColumns = [
      { 
        field: 'roll_no', 
        headerName: 'Roll No', 
        width: 100,
        headerAlign: 'center',
        align: 'center',
      },
      { 
        field: 'name', 
        headerName: 'Student Name', 
        width: 180,
        headerAlign: 'center',
      },
    ];

    // Add columns for each subject
    const subjectColumns = Array.from(new Set(
      Object.values(subjectData).flatMap(subjects => 
        subjects.map(s => s.subject_name)
      )
    )).map(subject => ({
      field: subject,
      headerName: subject,
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderMarksCell,
    }));

    const finalColumns = [
      ...baseColumns,
      ...subjectColumns,
      {
        field: 'totalMarks',
        headerName: 'Total',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <Typography fontWeight="bold">
            {params.row.totalMarksObtained}/{params.row.totalPossibleMarks}
          </Typography>
        ),
      },
      {
        field: 'avgPercentage',
        headerName: 'Average %',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <Chip
            label={`${params.value.toFixed(1)}%`}
            color={params.value >= 40 ? 'success' : 'error'}
            size="small"
          />
        ),
      },
      {
        field: 'grade',
        headerName: 'Grade',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <Chip
            label={params.value}
            color={
              params.value === 'A+' || params.value === 'A' ? 'success' :
              params.value === 'B' ? 'primary' :
              params.value === 'C' ? 'warning' :
              'error'
            }
            size="small"
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <Button
            variant="contained"
            startIcon={<VisibilityIcon />}
            size="small"
            onClick={() => handleViewAnswerSheet(params.row)}
            color="primary"
          >
            View
          </Button>
        ),
      },
    ];

    return finalColumns;
  };

  const renderAnswerSheet = (subject) => {
    if (!answerSheets[subject.subject_name]) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography color="text.secondary">
            No answer sheet available for this subject
          </Typography>
        </Box>
      );
    }

    const sheetData = answerSheets[subject.subject_name];
    const questions = Object.entries(sheetData.marks_info || {});

    return (
      <Box sx={{ p: 2 }}>
        {/* Subject Overview Card */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 3,
            background: 'linear-gradient(135deg, #f6f9fc 0%, #f1f4f8 100%)',
            borderRadius: 2
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Typography variant="h4" color="primary.main" gutterBottom>
                  {sheetData.marks_obtained}/{sheetData.total_marks}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Total Score
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Typography variant="h4" color="primary.main" gutterBottom>
                  {((sheetData.marks_obtained / sheetData.total_marks) * 100).toFixed(1)}%
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Percentage
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Chip
                  label="Corrected"
                  color="success"
                  sx={{ 
                    fontSize: '1.1rem',
                    padding: '20px 10px',
                    '& .MuiChip-label': { px: 2 }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Question Numbers */}
        <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
          {questions.map(([qId, qData], index) => (
            <Button
              key={qId}
              variant={selectedQuestion === qId ? "contained" : "outlined"}
              onClick={() => setSelectedQuestion(qId)}
              sx={{
                minWidth: '60px',
                height: '60px',
                borderRadius: '50%',
                transition: 'all 0.3s ease',
                transform: selectedQuestion === qId ? 'scale(1.1)' : 'scale(1)',
                '&:hover': {
                  transform: 'scale(1.1)',
                }
              }}
            >
              {qData.questionNo}
            </Button>
          ))}
        </Box>

        {/* Question Details and Answer Sheet */}
        {selectedQuestion && sheetData.marks_info[selectedQuestion] && (
          <Box>
            {/* Answer Sheet Image */}
            <Typography variant="h6" gutterBottom color="primary">
              Student's Answer
            </Typography>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                mb: 2,
              }}
            >
              {/* Zoom Controls */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  zIndex: 2,
                  display: 'flex',
                  gap: 1,
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '24px',
                  padding: '4px 8px',
                  boxShadow: 2,
                  // backdropFilter: 'blur(4px)',
                }}
              >
                <Tooltip title="Zoom Out">
                  <IconButton 
                    size="small" 
                    onClick={handleZoomOut}
                    disabled={zoomLevel <= 0.5}
                    sx={{
                      color: 'primary.main',
                      '&:disabled': {
                        color: 'action.disabled',
                      }
                    }}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                </Tooltip>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    minWidth: '50px',
                    justifyContent: 'center',
                    fontWeight: 'medium',
                    color: 'primary.main',
                  }}
                >
                  {Math.round(zoomLevel * 100)}%
                </Typography>
                <Tooltip title="Zoom In">
                  <IconButton 
                    size="small" 
                    onClick={handleZoomIn}
                    disabled={zoomLevel >= 3}
                    sx={{
                      color: 'primary.main',
                      '&:disabled': {
                        color: 'action.disabled',
                      }
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              
              {/* Image Container */}
              <Box
                sx={{
                  overflow: 'auto',
                  maxHeight: '70vh',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  bgcolor: 'background.default',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '50vh',
                    padding: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={sheetData.marks_info[selectedQuestion].details.answer}
                    alt={`Answer for question ${sheetData.marks_info[selectedQuestion].questionNo}`}
                    sx={{
                      maxWidth: '100%',
                      transform: `scale(${zoomLevel})`,
                      transformOrigin: 'center center',
                      transition: 'transform 0.2s ease-out',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* Teacher's Feedback */}
        {sheetData.feedback && (
          <Paper 
            elevation={3} 
            sx={{ 
              p: 3,
              mt: 3,
              borderRadius: 2,
              background: 'linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%)'
            }}
          >
            <Typography variant="h6" gutterBottom color="primary">
              Teacher's Feedback
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
              "{sheetData.feedback}"
            </Typography>
          </Paper>
        )}
      </Box>
    );
  };

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Student Marks</Typography>
            <Stack direction="row" spacing={1}>
              <Button 
                startIcon={<DownloadIcon />} 
                variant="outlined"
                onClick={() => {
                  const headers = ['Name', 'Roll No', 'Total Marks', 'Average %', 'Grade'];
                  const csvData = processedData.map(row => [
                    row.name,
                    row.rollNo,
                    `${row.totalMarksObtained}/${row.totalPossibleMarks}`,
                    `${row.avgPercentage.toFixed(1)}%`,
                    row.grade
                  ]);
                  
                  const csvContent = [
                    headers.join(','),
                    ...csvData.map(row => row.join(','))
                  ].join('\n');
                  
                  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'student_marks.csv';
                  link.click();
                }}
              >
                Export
              </Button>
              <Button 
                startIcon={<PrintIcon />} 
                variant="outlined"
                onClick={() => window.print()}
              >
                Print
              </Button>
            </Stack>
          </Stack>
        }
      />
      <CardContent>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={processedData}
            columns={getColumns()}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>

        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Answer Sheets - {selectedStudent?.name}
              </Typography>
              <IconButton onClick={handleCloseDialog} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading answer sheets...</Typography>
              </Box>
            ) : selectedStudent?.subjects?.length > 0 ? (
              <Box>
                <Tabs
                  value={selectedSubject}
                  onChange={(e, newValue) => setSelectedSubject(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
                >
                  {selectedStudent.subjects.map((subject, index) => (
                    <Tab
                      key={index}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          {getStatusIcon(subject.status)}
                          <Typography>{subject.subject_name}</Typography>
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                {selectedStudent.subjects[selectedSubject] && 
                  renderAnswerSheet(selectedStudent.subjects[selectedSubject])
                }
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                <Typography color="text.secondary">
                  No answer sheets available
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default MarksTableModern;
